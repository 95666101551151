<template>
  <div>
    <div id="threeDOM"></div>
    <div id="tag">标签内容</div>
  </div>
</template>
<script>

import * as THREE from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

export default {
  name: 'building',
  data() {
    return {
      scene: null,
      camera: null,
      renderer: null,
      ambientLight: null,
      cube: null
    };
  },
  mounted() {
    const scene = new THREE.Scene()
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 5;
    const width = window.innerWidth; //宽度
    const height = window.innerHeight; //高度
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(width, height); //设置three.js渲染区域的尺寸(像素px)
    renderer.render(scene, camera); //执行渲染操作
    // 设置相机控件轨道控制器OrbitControls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = 100; // 最小距离
    controls.maxDistance = 8000; // 最大距离
    // controls.target.set(1000, 0, 1000);
    controls.update();
    // 如果OrbitControls改变了相机参数，重新调用渲染器渲染三维场景
    // controls.addEventListener('change', function () {
    //   renderer.render(scene, camera); //执行渲染操作
    // })
    window.onresize = function () {
      // 重置渲染器输出画布canvas尺寸
      renderer.setSize(window.innerWidth, window.innerHeight);
      // 全屏情况下：设置观察范围长宽比aspect为窗口宽高比
      camera.aspect = window.innerWidth / window.innerHeight;
      // 渲染器执行render方法的时候会读取相机对象的投影矩阵属性projectionMatrix
      // 但是不会每渲染一帧，就通过相机的属性计算投影矩阵(节约计算资源)
      // 如果相机的一些属性发生了变化，需要执行updateProjectionMatrix ()方法更新相机的投影矩阵
      camera.updateProjectionMatrix();
    };
    renderer.setClearColor(0xb9d3ff, 0.4);

    document.getElementById('threeDOM').appendChild(renderer.domElement);
    // 创建地板网格和材质
    // const geometry = new THREE.PlaneGeometry(100, 100);
    // const material = new THREE.MeshBasicMaterial({color: 0x00ff00});
    // const floor = new THREE.Mesh(geometry, material);
    // scene.add(floor);
    const loader = new GLTFLoader();
    loader.load('http://localhost:8080/moxing.glb', (gltf) => {
      // gltf.scene.scale.set(40, 40, 40);
      scene.add(gltf.scene);
    });

// 渲染场景
    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }

    animate();
  },
  methods: {}
};
</script>
<style scoped>
.card-header {
  display: flex;
  align-items: center;
}
</style>
